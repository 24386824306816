import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Helmet } from "react-helmet";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
//import { Services } from "./components/services";
//import { Features } from "./components/features";
//import { Gallery } from "./components/gallery";
//import { Testimonials } from "./components/testimonials";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />

      {
      /*
      <Features data={landingPageData.Features} />
      <Testimonials data={landingPageData.Testimonials} />
      <Gallery data={landingPageData.Gallery} />
      <Services data={landingPageData.Services} />
      */
      } 
     
     <Helmet>
       <script type="text/javascript">
         {
         `
         var lastScrollTop;
           navbar = document.getElementById('menu');
           window.addEventListener('scroll',function(){
           var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
           if(scrollTop > lastScrollTop){
           navbar.style.top='-80px';
           }
           else{
           navbar.style.top='0';
           }
           lastScrollTop = scrollTop;
           });
           `
         }
       </script>
     </Helmet>

    </div>
  );
};

export default App;
